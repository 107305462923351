import { ClientTemplate, Component } from '@/components/ClientTemplate';
import { Pages, AutoLoginPageTemplate } from '@/types';

@Component
export default class ClientAutoLogin extends ClientTemplate<Pages.AutoLogin> {
  identifiers: string[] = [];

  async autoLogin(identifier: string) {
    const result = await this.pds.autoLogin({ identifier });
    this.router.changePage(result);
  }

  async logout(identifier: string) {
    const result = await this.pds.logout({ identifier });
    if (result.isFailure()) {
      this.router.changePage(result);
    } else if (result.value.page_to_show === this.Pages.AutoLogin) {
      this.identifiers = result.value.payload.autologin_identifiers;
    } else {
      this.router.changePage(result);
    }
  }

  created() {
    const page = this.store.getPage(this.Pages.AutoLogin);
    if (page) {
      this.identifiers = page.autologin_identifiers;
    } else {
      this.router.goTo(this.Pages.Login);
    }
  }

  build() {
    const data: AutoLoginPageTemplate = {
      loginLink: this.actions.create(() => {
        this.router.goTo(this.Pages.Login);
      }),
      identifiers: [],
    };
    data.identifiers = this.identifiers.map((identifier) => ({
      identifier,
      autoLoginLink: this.actions.create(() => {
        this.autoLogin(identifier);
      }),
      logoutLink: this.actions.create(() => {
        this.logout(identifier);
      }),
    }));
    return [Pages.AutoLogin, data] as const;
  }
}
